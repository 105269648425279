<template>
    <div class="page page-one" style="min-height: 100vh;font-size: 18px">
        <van-nav-bar class="one-nav" :border="false" :placeholder="true" :fixed="true" :title="$t('下载并安装')" left-arrow
                     @click-left="onClickLeft">
        </van-nav-bar>
        <div class="wrapper">
            <div class="bs-panel one-card">
                <div class="one-top u-m-b10">
                    <div>{{$t('Google身份验证器')}}</div>
                    <div><img src="../../assets/pictures/google@2x.png"></div>
                </div>
                <van-steps class="step-card" direction="vertical" :active="0">
                        <van-step>
                            <template #active-icon><div class="step-a">1</div></template>
                            <template #finish-icon><div class="step-a">1</div></template>
                            <template #inactive-icon><div class="step-b">1</div></template>
                            <div class="step-text">{{$t('下载验证器')}}</div>
                        </van-step>
                        <van-step>
                            <template #active-icon><div class="step-a">2</div></template>
                            <template #finish-icon><div class="step-a">2</div></template>
                            <template #inactive-icon><div class="step-b">2</div></template>
                            <div class="step-text">{{$t('扫描二维码')}}</div>
                        </van-step>
                        <van-step>
                            <template #active-icon><div class="step-a">3</div></template>
                            <template #finish-icon><div class="step-a">3</div></template>
                            <template #inactive-icon><div class="step-b">3</div></template>
                            <div class="step-text">{{$t('备份密钥')}}</div>
                        </van-step>
                        <van-step>
                            <template #active-icon><div class="step-a">4</div></template>
                            <template #finish-icon><div class="step-a">4</div></template>
                            <template #inactive-icon><div class="step-b">4</div></template>
                            <div class="step-text">{{$t('绑定验证器')}}</div>
                        </van-step>
                        <van-step>
                            <template #active-icon><div class="step-a">5</div></template>
                            <template #finish-icon><div class="step-a">5</div></template>
                            <template #inactive-icon><div class="step-b">5</div></template>
                            <div class="step-text">{{$t('绑定成功')}}</div>
                        </van-step>
                    </van-steps>
            </div>
            <div style="font-weight: bold;font-size:16px;margin-bottom:10px">
                {{$t('下载并安装')}}
            </div>
            <div class="down-foot" style="display: flex;justify-content: space-between">
                <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US" style="width: 50%">
                    <div class="down-btn">
                        <img src="../../assets/pictures/play@2x.png">
                        <div>{{$t('Google Play')}}</div>
                    </div>
                </a>
                <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank" style="width: 50%">
                    <div class="down-btn">
                        <img src="../../assets/pictures/icon_AppStore@2x.png">
                        <div>{{$t('App Store')}}</div>
                    </div>
                </a>
            </div>
            <div style="margin-top: 10px">
                <van-button round block type="warning" @click="url('/code-set-two')">{{$t('下一步')}}</van-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                show: false,
                password: '',
                old_password: '',
                new_password: '',
                q_password: '',
                withdraw_info: {},
            }
        },
        methods: {
            onClickLeft() {
                this.$router.go(-1)
            },
            url(url) {
                this.$router.push({
                    path: url
                })
            },
            isBind(){
                this.$axios.get('/gcode/is_bind').then((res) => {
                    if (res.data.code == 0) {
                        if(res.data.data.is_bind == 1){
                            this.url('/code-set');
                        }
                    }
                }).catch((err) => {
                    this.$toast.fail(err.message);
                });
            }
        },
        mounted() {
            localStorage.setItem('aqzx', '1');
            this.isBind();
        }
    }
</script>

<style scoped>
    .one-card{
        padding: 20px;
        margin-bottom: 20px;
        font-size: 14px;
    }
    .one-top{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .one-top img{
        width: 20px;
    }
    .down-foot{
        text-align: center;
        padding: 20px 0;
    }
    .down-foot .down-btn img{
        height: 28px;
    }
    .down-foot .down-btn div{
        margin-top: 10px;
    }
    

</style>